import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Cek apakah token ada di sessionStorage
        const token = sessionStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const Auth = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://webapi-portalnb.nawabhakti.sch.id/login', {
                username: username,
                password: password
            }, {
                withCredentials: true 
            });
            // Simpan token di sessionStorage
            sessionStorage.setItem('token', response.data.accessToken);
            navigate("/dashboard");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form className='box' onSubmit={Auth}>
                                {msg && <p className='has-text-centered has-text-danger'>{msg}</p>}
                                <div className="field mt-5">
                                    <label className="label">Username</label>
                                    <div className="controls">
                                        <input 
                                            type="text" 
                                            className='input' 
                                            placeholder='Username' 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)} 
                                        />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input 
                                            type="password" 
                                            className='input' 
                                            placeholder='***********'  
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)} 
                                        />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className='button is-success is-fullwidth'>Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
